body {
    margin: 0;
}

html { 
    /*background: linear-gradient(0deg, rgba(80, 80, 80, 0.5), rgba(80, 80, 80, 0.5)),  url(https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&dl=headway-F2KRf_QfCqw-unsplash.jpg&q=80&fm=jpg&crop=entropy&cs=tinysrgb) no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body, html{
    max-width: 100% !important;
    overflow-x: hidden !important;
}